import { Injectable } from '@angular/core';
import {Restangular} from 'ngx-restangular';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UsersInsuranceService {

    public usersInsuranceObservable = new BehaviorSubject(null);

    constructor(
        private restangular: Restangular,
    ) { }

    // PRIMARY

    getInsurancePrimary(userId: string, vendorId: string): Promise<any> {
        return this.restangular.one('users/insurance/primary', userId).one(vendorId).get().toPromise();
    }

    createInsurancePrimary(userId: string, vendorId: string, gender: string, metadata: any, tobaccoUseDate: string): Promise<any> {
        return this.restangular.one('users/insurance/primary', userId).one(vendorId).customPOST({
            gender,
            metadata,
            tobaccoUseDate,
        }).toPromise();
    }

    modifyInsurancePrimary(userId: string, vendorId: string, fields: any): Promise<any> {
        return this.restangular.one('users/insurance/primary', userId).one(vendorId).customPUT({
            ...fields
        }).toPromise();
    }

    deleteInsurancePrimary(userId: string, vendorId: string): Promise<any> {
        return this.restangular.one('users/insurance/primary', userId).one(vendorId).remove().toPromise();
    }

    // DEPENDENTS

    getInsuranceDependents(userId: string, vendorId: string): Promise<any> {
        return this.restangular.one('users/insurance/dependent', userId).one(vendorId).getList().toPromise();
    }

    createInsuranceDependent(userId: string, vendorId: string, gender: string, metadata: any, tobaccoUseDate: string, firstName: string, lastName: string, dob: string, relationship: string): Promise<any> {
        return this.restangular.one('users/insurance/dependent', userId).one(vendorId).customPOST({
            gender,
            metadata,
            tobaccoUseDate,
            firstName,
            lastName,
            dob,
            relationship,
        }).toPromise();
    }

    modifyInsuranceDependent(userId: string, dependentId: string, fields: any): Promise<any> {
        return this.restangular.one('users/insurance/dependent', userId).one(dependentId).customPUT({
            ...fields
        }).toPromise();
    }

    deleteInsuranceDependent(userId: string, dependentId: string): Promise<any> {
        return this.restangular.one('users/insurance/dependent', userId).one(dependentId).remove().toPromise();
    }

    // BENEFICIARIES

    getInsuranceBeneficiaries(userId: string, vendorId: string): Promise<any> {
        return this.restangular.one('users/insurance/beneficiary', userId).one(vendorId).getList().toPromise();
    }

    createInsuranceBeneficiary(userId: string, vendorId: string, firstName: string, lastName: string, ssn: string, relationship: string, beneficiaryType: string): Promise<any> {
        return this.restangular.one('users/insurance/beneficiary', userId).one(vendorId).customPOST({
            beneficiaryType,
            relationship,
            firstName,
            lastName,
            ssn,
        }).toPromise();
    }

    modifyInsuranceBeneficiary(userId: string, beneficiaryId: string, fields: any): Promise<any> {
        return this.restangular.one('users/insurance/beneficiary', userId).one(beneficiaryId).customPUT({
            ...fields
        }).toPromise();
    }

    deleteInsuranceBeneficiary(userId: string, beneficiaryId: string): Promise<any> {
        return this.restangular.one('users/insurance/beneficiary', userId).one(beneficiaryId).remove().toPromise();
    }

    getInsuranceStateForUser(userId): Promise<any> {
        return this.restangular.one('users/insurance', 'all').one('user', userId).getList().toPromise();
    }

    getInsuranceProductsForUser(userId): Promise<any> {
        return this.restangular.one('users/insurance', 'products').one('user', userId).get().toPromise();
    }

    // CARRIER UPDATES

    updateCarriers(userId): Promise<any> {
        return this.restangular.one('users/insurance/carrier/update', userId).put().toPromise();
    }

 }