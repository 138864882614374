import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';
import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import * as AWS from 'aws-sdk';
import { SecretService } from '../secret/secret.service';
import { SecretsInterface } from '../../interfaces/secrets/secrets.interface';
import { ToastComponent } from '../../components/toast/toast.component';
import { AppSettings } from '../../app.settings';
import { HttpClient } from '@angular/common/http';
import { Storage } from 'aws-sdk/clients/ec2';
import { findPartialKeyRelatedToCognito } from '../../helpers/json-helper';
import { LocalStorageServiceProvider } from '../local-storage-service/local-storage-service';


export interface userCredentialsInterface {
  username: string;
  password: string;
}

@Injectable({
  providedIn: 'root'
})
export class CognitoMfaService {
  currentCognitoUser: any;
  private cognito: any;
  private secrets: SecretsInterface;


  constructor(
      private secretService: SecretService,
      private toastComponent: ToastComponent,
      private http: HttpClient,
      private localStorageService: LocalStorageServiceProvider,
  ) {
    this.getAppSecrets();
  }

  getAppSecrets() {
    this.secretService.secrets.subscribe({
      next: (secrets: SecretsInterface): void => {
        if (secrets) {
          this.secrets = secrets;
          this.configCognitoAwsSdk();
        }
      }
    });
  }

  configCognitoAwsSdk(): void {
    AWS.config.update({
      region: this.secrets.region,
      accessKeyId: this.secrets.accessKeyId,
      secretAccessKey: this.secrets.secretAccessKey
    });
    this.cognito = new AWS.CognitoIdentityServiceProvider();
  }

  async cognitoMFASignIn(userCredentials: userCredentialsInterface): Promise<CognitoUser> {
    try {
        this.currentCognitoUser = await Auth.signIn(userCredentials);
        return this.currentCognitoUser;
    } catch (error) {
        void this.toastComponent.presentToast('Error while saving cognito information');
    }
  }

  async cognitoMFASignInWithoutPassword(email: string): Promise<CognitoUser> {
    try {
      this.currentCognitoUser = await Auth.signIn(email);
      return this.currentCognitoUser;
    } catch (error: any) {
      if (JSON.stringify(error).includes('UserLambdaValidationException')) {
        await this.cognitoMFASignUp({username: email, password: this.generatePassword()}, true);
        this.currentCognitoUser = await Auth.signIn(email);
        return this.currentCognitoUser;
      } else {
        void this.toastComponent.presentToast(`${error}`);
      }
    }
  }

  async cognitoCustomChallengeAnswer(code: string): Promise<any> {
    const customChallenge = await Auth.sendCustomChallengeAnswer(this.currentCognitoUser, code);
    if (customChallenge && customChallenge.signInUserSession) {
      this.currentCognitoUser = customChallenge;
      return customChallenge;
    }
  }

  async cognitoMFASignUp(user: userCredentialsInterface, isGenericPassword = false): Promise<any> {
    let params: {
      AuthFlow: string
      ClientId: string
      AuthParameters: { USERNAME: string, PASSWORD: string },
      ClientMetadata?: { isGenericPassword: string }
    } = {
      AuthFlow: 'USER_PASSWORD_AUTH',
      ClientId: this.secrets.userPoolWebClientId,
      AuthParameters: {
        'USERNAME': user.username,
        'PASSWORD': user.password
      }
    };
    if (isGenericPassword) {
      params = {
        ...params,
        ClientMetadata: {
          isGenericPassword: isGenericPassword.toString()
        }
      };
    }
    return this.cognito.initiateAuth(params).promise();
  }

  async checkIfUserExist(user: userCredentialsInterface): Promise<boolean | null> {
    const params = {
      UserPoolId: this.secrets.userPoolId,
      Username: user.username,
    };
    return new Promise((resolve, reject): void => {
      this.cognito.adminGetUser(params, (err: any): void => {
        if (err) {
          if (err.code === 'UserNotFoundException') {
            resolve(false);
          } else {
            reject(null);
          }
        } else {
          resolve(true);
        }
      });
    });
  }

  cognitoMFAConfirmSignIn(TOTPCode: string, mfaType: 'SOFTWARE_TOKEN_MFA'): Promise<any> {
    return Auth.confirmSignIn(this.currentCognitoUser, TOTPCode, mfaType);
  }

  async cognitoSetupTOTP(): Promise<string> {
    await this.cognitoUpdateCurrentUser();
    return Auth.setupTOTP(this.currentCognitoUser);
  }

  async cognitoVerifyTOTPToken(totpCode: string): Promise<CognitoUserSession> {
    await this.cognitoUpdateCurrentUser();
    return Auth.verifyTotpToken(this.currentCognitoUser, totpCode);
  }

  async cognitoSetPreferredMFA(mfaType: 'TOTP'): Promise<string> {
    await this.cognitoUpdateCurrentUser();
    return Auth.setPreferredMFA(this.currentCognitoUser, mfaType);
  }

  async cognitoRemoveMFA(): Promise<any> {
    await this.cognitoUpdateCurrentUser();
    await Auth.setPreferredMFA(this.currentCognitoUser, 'NOMFA');
    return Auth.forgetDevice();
  }

  async cognitoUpdateCurrentUser(): Promise<void> {
    await Auth.currentAuthenticatedUser().then((user: CognitoUser): void => {
      this.currentCognitoUser = user;
    });
  }

  async cognitoRegisterUserDevice(): Promise<any> {
    return await Auth.rememberDevice();
  }

  async getCurrentCognitoUser(): Promise<any> {
    await this.cognitoUpdateCurrentUser();
    return Auth.getPreferredMFA(this.currentCognitoUser);
  }

  async cognitoSignOut(): Promise<any> {
    return Auth.signOut();
  }

  async getAccessTokenUsingRefreshToken(refreshToken: string, deviceKey: string): Promise<any> {
      try {
          const params = {
              AuthFlow: 'REFRESH_TOKEN_AUTH',
              ClientId: this.secrets.userPoolWebClientId,
              AuthParameters: {
                  REFRESH_TOKEN: refreshToken,
                  DEVICE_KEY: deviceKey
              }
          };
          return await this.cognito.initiateAuth(params).promise();
      } catch (error) {
          console.log('An error occurred while getting new AccessToken using RefreshToken', error);
      }
  }

  async saveCognitoRefreshTokenInformation(): Promise<void> {
      const userEmail = this.currentCognitoUser.attributes.email;
      const storage: Storage = this.currentCognitoUser.pool.storage;
      const refreshToken = findPartialKeyRelatedToCognito(storage, 'refreshToken');
      const deviceKey = findPartialKeyRelatedToCognito(storage, 'deviceKey');
      const body = {
          userEmail,
          refreshToken,
          deviceKey
      };
      const jwtToken = this.localStorageService.get('JWTToken');
      const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwtToken}`
      };
      try {
          await this.http.post(
              `${AppSettings.getEndpoint()}/auth/save-cognito-refresh-token`,
              body,
              { headers }
          ).toPromise();
      } catch (error) {
          console.log(`Error while saving refreshToken: ${error}`);
      }
  }
  generatePassword(): string {
    const minLength = 12;
    const numbers = '0123456789';
    const upperCase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lowerCase = 'abcdefghijklmnopqrstuvwxyz';
    const specialChars = '!@#$%^&*()-_=+[]{}|;:,.<>?';
    const allChars = numbers + upperCase + lowerCase + specialChars;
    const requiredChars = [
      numbers[Math.floor(Math.random() * numbers.length)],
      upperCase[Math.floor(Math.random() * upperCase.length)],
      lowerCase[Math.floor(Math.random() * lowerCase.length)],
      specialChars[Math.floor(Math.random() * specialChars.length)],
    ];
    const remainingLength = minLength - requiredChars.length;
    for (let i = 0; i < remainingLength; i++) {
      requiredChars.push(allChars[Math.floor(Math.random() * allChars.length)]);
    }
    return requiredChars
        .sort(() => Math.random() - 0.5)
        .join('');
  }
}
