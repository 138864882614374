import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import * as moment from 'moment';
import {UsersInsuranceService} from '../../services/usersinsurance/users.insurance.service';
import {UsersService} from '../../services/users/users.service';
import {ToastComponent} from '../toast/toast.component';
import {UtilsService} from '../../services/utils/utils.service';

@Component({
  selector: 'app-insurance-primary',
  templateUrl: './insurance-primary.component.html',
  styleUrls: ['./insurance-primary.component.scss']
})
export class InsurancePrimaryComponent implements OnInit, OnChanges {

  @Input() vendorProduct;

  public form;
  public maxDate = moment().toDate();
  protected primaryInfo: any;
  protected tobaccoDate = '';

  constructor(
      private formBuilder: FormBuilder,
      private usersInsuranceService: UsersInsuranceService,
      private usersService: UsersService,
      private toastComponent: ToastComponent,
      private utilsService: UtilsService
  ) {
    this.form = this.formBuilder.group({
      gender: new FormControl('', Validators.required),
      tobaccoUseDate: new FormControl('')
    });

  }

  ngOnChanges(event): void {
    console.log('PRIMARY CHANGES', event)
    this.ngOnInit();
  }

  ngOnInit(): void {
    this.usersInsuranceService.getInsurancePrimary(this.usersService.getCurrentUserId(), this.vendorProductId()).then(result => {
      if (result.id) {
        this.primaryInfo = result;
        this.resetClicked();
        setTimeout(() => {
          this.resetClicked();
        }, 500);
      }
    });
  }

  resetClicked(): void {
    this.form.reset();
    this.form.get('gender')?.setValue(this.primaryInfo?.gender);
    this.form.get('tobaccoUseDate')?.setValue(this.primaryInfo?.tobaccoUseDate);
    this.tobaccoDate = this.primaryInfo?.tobaccoUseDate;
  }

  removeTobaccoDate(): void {
    this.form.get('tobaccoUseDate')?.setValue('');
  }

  saveClicked(): void {
    const newInfo = {
      gender: this.form.get('gender')?.value,
      tobaccoUseDate: this.utilsService.convertDate(this.form.get('tobaccoUseDate')?.value)
    };
    if (this.primaryInfo && !this.primaryInfo.fromWallit) {
      this.usersInsuranceService.modifyInsurancePrimary(this.usersService.getCurrentUserId(), this.vendorProductId(), newInfo).then(_ => {
        this.usersInsuranceService.updateCarriers(this.usersService.getCurrentUserId());
        this.toastComponent.presentToast('User insurance primary info updated');
        this.resetClicked();
      });
    } else {
      this.usersInsuranceService.createInsurancePrimary(this.usersService.getCurrentUserId(), this.vendorProductId(), this.form.get('gender')?.value, {}, this.utilsService.convertDate(this.form.get('tobaccoUseDate')?.value)
      ).then(_ => {
        this.primaryInfo = newInfo;
        this.toastComponent.presentToast('User insurance primary info created');
        this.usersInsuranceService.usersInsuranceObservable.next(null);
        this.resetClicked();
      });
    }
  }

  vendorProductId() {
    return this.vendorProduct?.id || 'wallit';
  }

}
