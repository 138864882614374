import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {ApiService} from '../services/api/api.service';
import {SessionService} from '../services/session/session.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
      public apiService: ApiService,
      private sessionService: SessionService,
      public router: Router
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const idTokenKey: string = Object.keys(localStorage).find(key => key.startsWith('CognitoIdentityServiceProvider') && key.endsWith('idToken'));
    const isApiAuthenticated: boolean = this.apiService.isAuthenticated();
    if (!isApiAuthenticated || !idTokenKey) {
      this.sessionService.clearLocalStorageAndRedirectUser(state);
      return false;
    } else {
      return true;
    }
  }

}
