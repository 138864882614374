export class AppSettings {
  private static urlApi = '';

  private static TEAM_ADMIN_ENVIRONMENT = 'https://teamadmin.wallit.app';
  private static TEAM_ADMIN_QA_ENVIRONMENT = 'https://teamadmin.qa.wallit.app';
  private static TEAMADMIN_STAGING_ENVIRONMENT = 'https://teamadmin.staging.wallit.app';
  private static TEAMADMIN_DEV_ENVIRONMENT = 'https://wallit-team-dev.web.app';

  public static HELP_ENVIRONMENT = 'https://www.wallit.app/';
  private static QA_APP_ENVIRONMENT = 'https://qa.wallit.app';
  private static PRODUCTION_APP_ENVIRONMENT = 'https://my.wallit.app';
  private static DEV_APP_ENVIRONMENT = 'https://dev.wallit.app';
  private static DEMO_APP_ENVIRONMENT = 'https://demo.wallit.app';
  private static LOCAL_APP_ENVIRONMENT = 'http://localhost:4000';

  private static PRODUCTION_ENDPOINT = 'https://prod.api.wallit.app';
  private static STAGING_ENDPOINT = 'https://staging.api.wallit.app';
  private static QA_ENDPOINT = 'https://qa.api.wallit.app';
  private static DEMO_ENDPOINT = 'https://demo.api.wallit.app';
  private static DEV_ENDPOINT = 'https://dev.api.wallit.app';
  private static LOCAL_ENDPOINT = 'http://localhost:8080';

  public static isDemo: boolean = window.location.host.indexOf('demo') >= 0;
  public static isDev: boolean = window.location.host.indexOf('dev') >= 0;
  public static isQa: boolean = window.location.host.indexOf('qa') >= 0;
  public static isStaging: boolean = window.location.host.indexOf('staging') >= 0;
  public static isProd: boolean = window.location.host.indexOf('my') >= 0 || window.location.host.indexOf('prod') >= 0;
  private static isLocal: boolean = window.location.host.indexOf('local') >= 0;
  private static isCordova: boolean = window.location.host.indexOf('capacitor') >= 0;

  private static STRIPE_SANDBOX = {
    key: 'pk_test_51IG3Y1JQ7tkZop4Eyg8j9kMh65HLTFwXeMzJ9KEeI7HqLlvHuLCzFWrgwEFvBKrb2CLrKmETmlLpSs4b1yZ1QhAA00zSmmle7e',
  };

  private static STRIPE_PROD = {
    key: 'pk_live_51IG3Y1JQ7tkZop4Edab3EB5mTzj4hwshJinyAmgrbn3J0kJ7hbQlBRzuU5q6AZnTpsd9w96KEhMeVyJZZ5tbBDLT00OxYVZsEf',
  };

  private static DWOLLA_SANDBOX = 'sandbox';
  private static DWOLLA_PROD = 'prod';

  private static PLAID_SANDBOX = {
    env: 'sandbox',
    key: '2abf2e2c85785d0f31bb220328d26b',
  };

  private static PLAID_PROD = {
    env: 'production',
    key: '2abf2e2c85785d0f31bb220328d26b',
  };

  private static PLAID_DEVELOPMENT = {
    env: 'development',
    key: '2abf2e2c85785d0f31bb220328d26b',
  };

  private static LOGGLY_SANDBOX = {
    logglyKey: 'ac1f4368-fe7f-40c2-8446-424702d9b1c2',
    sendConsoleErrors: true,
    tag: 'loggly-logger',
  };

  private static LOGGLY_PROD = {
    logglyKey: 'ac1f4368-fe7f-40c2-8446-424702d9b1c2',
    sendConsoleErrors: false,
    tag: 'loggly-logger',
  };

  private static QA_REWARDS = {
    wallitCounterParty: 'cp_11espnf3rt4kg',
  };

  private static PRODUCTION_REWARDS = {
    wallitCounterParty: 'cp_11espn36g686q',
  };

  public static supportMode = false;

  public static API_ENDPOINT: string = AppSettings.getEndpoint();
  public static LOGGLY_CONFIG = AppSettings.getLogglyConfig();
  public static PLAID_ENVIRONMENT = AppSettings.getPlaidEnvironment();

  public static getAppEndpoint() {
    switch (AppSettings.urlApi) {
      case 'local':
        return this.LOCAL_APP_ENVIRONMENT;
      case 'demo':
        return this.DEMO_APP_ENVIRONMENT;
      case 'dev':
        return this.DEV_APP_ENVIRONMENT;
      case 'qa':
        return this.QA_APP_ENVIRONMENT;
      case 'staging':
        return this.PRODUCTION_APP_ENVIRONMENT;
      case 'prod':
      case 'localprod':
        return this.PRODUCTION_APP_ENVIRONMENT;
      default:
        if (this.isDemo) {
          return this.PRODUCTION_APP_ENVIRONMENT;
        } else if (this.isQa) {
          return this.QA_APP_ENVIRONMENT;
        } else if (this.isStaging) {
          return this.PRODUCTION_APP_ENVIRONMENT;
        } else if (this.isProd) {
          return this.PRODUCTION_APP_ENVIRONMENT;
        } else if (this.isDev) {
          return this.DEV_APP_ENVIRONMENT;
        } else if (this.isLocal) {
          return this.LOCAL_APP_ENVIRONMENT;
        } else {
          return this.PRODUCTION_APP_ENVIRONMENT;
        }
    }
  }

  public static getTeamAdminEndpoint() {
    switch (AppSettings.urlApi) {
      case 'local':
        return this.TEAM_ADMIN_QA_ENVIRONMENT;
      case 'demo':
        return this.TEAM_ADMIN_ENVIRONMENT;
      case 'qa':
        return this.TEAM_ADMIN_QA_ENVIRONMENT;
      case 'staging':
        return this.TEAMADMIN_STAGING_ENVIRONMENT;
      case 'prod':
      case 'localprod':
        return this.TEAM_ADMIN_ENVIRONMENT;
      case 'dev':
        return this.TEAMADMIN_DEV_ENVIRONMENT;
      default:
        if (this.isDemo) {
          return this.TEAM_ADMIN_ENVIRONMENT;
        } else if (this.isQa || this.isLocal) {
          return this.TEAM_ADMIN_QA_ENVIRONMENT;
        } else if (this.isStaging) {
          return this.TEAMADMIN_STAGING_ENVIRONMENT;
        } else if (this.isProd) {
          return this.TEAM_ADMIN_ENVIRONMENT;
        } else if (this.isDev) {
          return this.TEAMADMIN_DEV_ENVIRONMENT;
        } else {
          return this.TEAM_ADMIN_ENVIRONMENT;
        }
    }
  }

  public static getStripeInfo() {
    switch (new URLSearchParams(document.location.search).get('api')) {
      case 'local':
        return this.STRIPE_SANDBOX;
      case 'demo':
        return this.STRIPE_SANDBOX;
      case 'dev':
        return this.STRIPE_SANDBOX;
      case 'qa':
        return this.STRIPE_SANDBOX;
      case 'staging':
        return this.STRIPE_PROD;
      case 'prod':
      case 'localprod':
        return this.STRIPE_PROD;
      default:
        if (this.isDemo) {
          return this.STRIPE_PROD;
        } else if (this.isDev) {
          return this.STRIPE_SANDBOX;
        } else if (this.isQa || this.isLocal) {
          return this.STRIPE_SANDBOX;
        } else if (this.isStaging) {
          return this.STRIPE_PROD;
        } else if (this.isProd) {
          return this.STRIPE_PROD;
        } else {
          return this.STRIPE_PROD;
        }
    }
  }

  public static getRewardsInfo(): any {
    return this.isDemo || this.isQa ? AppSettings.QA_REWARDS : AppSettings.PRODUCTION_REWARDS;
  }

  public static getReleaseVersion(): string {
    if (this.isDemo) {
      return 'Demo';
    } else if (this.isQa || this.isLocal) {
      return 'QA';
    } else if (this.isStaging) {
      return '';
    } else if (this.isProd) {
      return '';
    } else if (this.isDev) {
      return 'Dev';
    } else {
      return '';
    }
  }

  public static getCurrentEndpointEnv(): string {
    if (this.isDemo) {
      return 'demo';
    } else if (this.isQa) {
      return 'qa';
    } else if (this.isStaging) {
      return 'stag';
    } else if (this.isProd) {
      return 'prod';
    } else if (this.isDev) {
      return 'dev';
    } else if (this.isLocal) {
      return 'local';
    } else {
      return '';
    }
  }

  public static getBrandOverride(): string | undefined | null {
    let storageBrand = localStorage.getItem('currentBrand');
    if (storageBrand === 'undefined') {
      storageBrand = undefined;
    }
    let override = new URLSearchParams(document.location.search).get('brand');
    if (override === 'undefined') {
      override = undefined;
    }
    if (override) {
      localStorage.setItem('currentBrand', override);
      return override;
    }
    if (!override && storageBrand && this.isCordova) {
      return storageBrand;
    }
    return '';
  }

  public static getEndpoint() {
    AppSettings.urlApi = new URLSearchParams(document.location.search).get('api');
    switch (AppSettings.urlApi) {
      case 'local':
      case 'localprod':
        this.isLocal = true;
        return this.LOCAL_ENDPOINT;
      case 'demo':
        return this.DEMO_ENDPOINT;
      case 'qa':
        return this.QA_ENDPOINT;
      case 'dev':
        return this.DEV_ENDPOINT;
      case 'staging':
        return this.STAGING_ENDPOINT;
      case 'prod':
        return this.PRODUCTION_ENDPOINT;
      default:
        if (this.isDemo) {
          return this.DEMO_ENDPOINT;
        } else if (this.isQa || this.isLocal) {
          return this.QA_ENDPOINT;
        } else if (this.isDev) {
          return this.DEV_ENDPOINT;
        } else if (this.isStaging) {
          return this.STAGING_ENDPOINT;
        } else if (this.isProd) {
          return this.PRODUCTION_ENDPOINT;
        } else {
          return this.PRODUCTION_ENDPOINT;
        }
    }
  }

  public static getPlaidEnvironment() {
    switch (new URLSearchParams(document.location.search).get('api')) {
      case 'local':
        return this.PLAID_SANDBOX;
      case 'demo':
        return this.PLAID_SANDBOX;
      case 'dev':
        return this.PLAID_SANDBOX;
      case 'qa':
        return this.PLAID_SANDBOX;
      case 'staging':
        return this.PLAID_PROD;
      case 'prod':
      case 'localprod':
        return this.PLAID_PROD;
      default:
        if (this.isQa || this.isDev || this.isLocal) {
          return this.PLAID_SANDBOX;
        } else {
          return this.PLAID_PROD;
        }
    }
  }

  public static getLogglyConfig() {
    if (this.isDemo || this.isQa || this.isDev || this.isLocal) {
      return this.LOGGLY_SANDBOX;
    } else {
      return this.LOGGLY_PROD;
    }
  }
}

const LOG = !AppSettings.isProd && !AppSettings.isStaging ? console.log.bind(console) : function() { };
