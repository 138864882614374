export function findPartialKey(object: Object, partialKey: string) {
    for (const key in object) {
        if (key.toLowerCase().includes(partialKey.toLowerCase())) {
            return object[key];
        }
    }
}

export function findPartialKeyRelatedToCognito(object: Object, partialKey: string) {
    for (const key in object) {
        if (key.toLowerCase().startsWith('CognitoIdentityServiceProvider'.toLowerCase()) && key.toLowerCase().endsWith(partialKey.toLowerCase())) {
            return object[key];
        }
    }
}
