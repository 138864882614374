import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Restangular } from 'ngx-restangular';
import { ApiService } from '../api/api.service';
import Avatar from 'avatar-initials';
import { SessionService } from '../session/session.service';
import { CognitoUser } from 'amazon-cognito-identity-js';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  ME_UPDATE_TIME = 100;

  private reload = true;
  private reloadAvatar = false;
  private meObservable: BehaviorSubject<any>;
  cognitoUser: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(private apiService: ApiService, protected restangular: Restangular) {
    const _this = this;
    this.meObservable = new BehaviorSubject(null);
    setInterval(() => {
      if (_this.reload && apiService.isAuthenticated()) {
        _this.reload = false;
        _this.restangular
          .one('me')
          .get()
          .toPromise()
          .then((data) => {
            // Check if data and data.user exist before accessing avatar
            if (data && data.user) {
              if (_this.reloadAvatar) {
                data.user.avatar = data.user.avatar + '?rand=' + Math.random().toString(36).substring(7);
              }
              _this.meObservable.next(data);
            } else {
              console.error('User data is undefined or invalid:', data);
            }
          })
          .catch((error) => {
            _this.reload = true;
          });
      }
    }, this.ME_UPDATE_TIME);
  }

  myWallit(): any {
    return this.restangular.one('mywallit').get().toPromise();
  }

  me(): any {
    return this.meObservable.getValue();
  }

  isChild(id?: string): boolean {
    if (id) {
      return this.lookupFamilyMember(id).permissionLevel === 1;
    }
    const me = this.me();
    return me && me.user ? me.user.permissionLevel === 1 : false;
  }

  isParent(): boolean {
    const me = this.me();
    return me && me.user ? me.user.permissionLevel === 8 : false;
  }

  isAdmin(): boolean {
    const me = this.me();
    return me && me.user ? me.user.permissionLevel > 8 : false;
  }

  isCoparent(): boolean {
    const me = this.me();
    if (!me && !me.user && !me.family) {
      return false;
    }
    return me.user.permissionLevel === 8 && me.family.ownerId !== me.user.id;
  }

  getCurrentUserId() {
    return this.me() ? this.me().user.id : '';
  }

  getCurrentFamilyId() {
    return this.me() ? this.me().family.id : '';
  }

  getCurrentEmail() {
    return this.me() ? this.me().user.email : '';
  }

  getCurrentUserName() {
    return this.me() ? (this.me().user.firstName || '') + ' ' + (this.me().user.lastName || '') : '';
  }

  isMemberCoparent(member): boolean {
    const me = this.me();
    return member.userType === 'parent' && member.userId !== me.family.ownerId;
  }

  public getFamilyOwnerId(): string {
    return this.me().family.ownerId;
  }

  public clearAndReloadMe() {
    this.meObservable.next(undefined);
    this.reloadMe();
  }

  public reloadMe(reloadAvatar = false) {
    this.reload = true;
    this.reloadAvatar = true;
    /*
        this.me().usersFamily.forEach(member => {
          this.firestoreService.members.update({
            forUserId: member.userId,
            id: this.me().user.id
          });
        });
        if (this.firstTime) {
          this.firstTime = false;
          this.firestoreService.members.monitor(this.me().user.id, _ => {
            this.reload = true;
          });
        }
        */
  }

  public meSubscribe(response) {
    this.meObservable.subscribe(response);
  }

  isOnboardingCompleted() {
    return this.me().family;
  }

  public lookupFamilyMember(id: string) {
    const me = this.me();
    if (me && me.user.id === id) {
      return me.user;
    }
    return me ? me.usersFamily.find((member) => member.userId === id) : '';
  }

  public setPhoneNumber(number: string, familyId = '', userId = ''): Promise<any> {
    const me = this.me();
    if (familyId !== '') {
      return this.restangular
        .one('families', familyId)
        .one('users', userId)
        .one('phoneNumbers', number)
        .customPUT()
        .toPromise();
    } else {
      return this.restangular.one('users', me.user.id).one('phoneNumbers', number).customPUT().toPromise();
    }
  }

  public removePhoneNumber(number: string): Promise<any> {
    const me = this.me();
    return this.restangular.one('users', me.user.id).one('phoneNumbers', number).remove().toPromise();
  }

  public setAvatar(image: any) {
    const me = this.me();
    return this.restangular
      .one('users', me.user.id)
      .all('avatar')
      .customPUT(image, undefined, undefined, { 'content-type': 'image/*' })
      .toPromise();
  }

  public deleteAvatar() {
    return this.restangular.one('users', this.me().user.id).all('avatar').remove().toPromise();
  }

  public setNotificationSettings(email: boolean, sms: boolean, offers: boolean) {
    const data = {
      emailSetting: +email,
      smsSetting: +sms,
      offersSetting: +offers,
    };
    const me = this.me();
    return this.restangular.one('users', me.user.id).all('notifications-settings').customPUT(data).toPromise();
  }

  public updateUser(data: any): Promise<any> {
    const me = this.me();
    return this.restangular.one('users', me.user.id).customPATCH(data).toPromise();
  }

  public requestPasswordReset(userOrEmail: string, phone: string): Promise<any> {
    return this.restangular
      .one('public')
      .one('users')
      .one('reset-password')
      .customPOST({ emailOrUsername: userOrEmail, phone: phone })
      .toPromise();
  }

  public resetPassword(resetCode: string, password: string, passwordConfirmation: string): Promise<any> {
    return this.restangular
      .one('auth')
      .one('user-password')
      .one('resetPassword', resetCode)
      .customPUT({
        password: password,
        passwordConfirmation: passwordConfirmation,
      })
      .toPromise();
  }

  public recoverUsername(email: string, phone: string): Promise<any> {
    return this.restangular
      .one('public')
      .one('users')
      .one('nickname-recovery')
      .customPOST({ email: email, phone: phone })
      .toPromise();
  }

  public queryUsers(query: string): Promise<any> {
    return this.restangular.one('public').one(`users?q=${query}`).getList().toPromise();
  }

  public queryUserById(familyId: string, userId: string, id: string): Promise<any> {
    return this.restangular.one('families', familyId).one('users', userId).one('finduser', id).get().toPromise();
  }

  public findFriends(familyId: string, userId: string, contacts): Promise<any> {
    return this.restangular.one('families', familyId).one('users', userId).one('find').customPOST(contacts).toPromise();
  }

  public getRecentUsers(userId: string): Promise<any> {
    return this.restangular.one('users', userId).one('recentusers').getList()().toPromise();
  }

  getAvatarOld(user) {
    if (user.avatar && user.avatar.includes('no_avatar.png')) {
      return 'https://ui-avatars.com/api/?size=128&name=' + user.firstName + '%20' + user.lastName;
    } else {
      return user.avatar;
    }
  }

  getAvatar(user, domElement): boolean {
    if ((user.avatar && user.avatar.includes('no_avatar.png')) || !user.avatar) {
      const avatar = new Avatar(domElement, {
        useGravatar: false,
        initials: user.firstName ? user.firstName.charAt(0) + user.lastName.charAt(0) : user.email.charAt(0),
      });
      return true;
    } else {
      domElement.src = user.avatar;
      return user.avatar;
    }
  }

  requestAccountDelete() {
    return this.restangular.one('users', this.getCurrentUserId()).one('requestdelete').customPUT().toPromise();
  }

  validateEmailCode(userId, code) {
    return this.restangular.one('users', userId).one('validatemail', code).customPUT().toPromise();
  }

  isEmailValidated(userId) {
    return this.restangular.one('users', userId).one('isemailvalidated').get().toPromise();
  }

  resendEmailValidation(email?) {
    return this.restangular
      .one('users', this.getCurrentUserId())
      .one('generatevalidationemail')
      .customPUT({ email })
      .toPromise();
  }
}
