import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import YouTubePlayer from 'youtube-player';
import {CommissionJunctionService} from '../../services/commissionjunction/commissionjunction.service';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-video-carousel',
  templateUrl: './video-carousel.component.html',
  styleUrls: ['./video-carousel.component.scss'],
})
export class VideoCarouselComponent implements OnInit, AfterViewInit {

  @Input() title;
  @Input() categories;

  protected rebateItems;
  protected slideOpts = {
    spaceBetween: -40,
    /*
    pagination: {
      type: 'bullets',
      clickable: true,
      loop: false,
      el: '.swiper-pagination'
    }
    */
  };

  constructor(
      private cjService: CommissionJunctionService,
      private domSanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.cjService.productSearch('', '').then(products => {
      this.rebateItems = products;
      this.rebateItems = this.rebateItems.filter(item => {
        if (!item.video) {
          return false;
        }
        if (!this.categories || this.categories.length === 0) {
          return true;
        }
        if (item.video.type === 'html') {
          item.video.html = this.domSanitizer.bypassSecurityTrustHtml(item.video.html);
        }
        return this.categories.some(r => item.bandCategories && item.bandCategories.includes(r));
      });
      this.rebateItems.forEach((item, index) => {
        item.htmlId = `video-${index}`;
      });
      setTimeout(() => {
        this.rebateItems.forEach(item => {
          if (item.video.type === 'youtube') {
            const player = YouTubePlayer(item.htmlId);
            player.loadVideoById(item.video.id);
            player.stopVideo();
          }
          const playerElement = document.getElementById(item.htmlId);
          if (playerElement) {
            playerElement.style.width = '100%';
            playerElement.style.height = '240px';
          }
        });
      }, 100);
    });
  }

  ngAfterViewInit() {
  }

}
